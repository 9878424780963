import { render, staticRenderFns } from "./SupersetChartViewPie.vue?vue&type=template&id=72d79f67"
import script from "./SupersetChartViewPie.vue?vue&type=script&setup=true&lang=ts"
export * from "./SupersetChartViewPie.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChartPie: require('/app/components/Chart/ChartPie.vue').default})
