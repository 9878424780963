import { render, staticRenderFns } from "./index.vue?vue&type=template&id=482d5432"
import script from "./index.vue?vue&type=script&setup=true&lang=ts"
export * from "./index.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SupersetChartViewBar: require('/app/components/Superset/SupersetChartView/SupersetChartViewBar.vue').default,SupersetChartViewLine: require('/app/components/Superset/SupersetChartView/SupersetChartViewLine.vue').default,SupersetChartViewPie: require('/app/components/Superset/SupersetChartView/SupersetChartViewPie.vue').default,SupersetChartViewTable: require('/app/components/Superset/SupersetChartView/SupersetChartViewTable.vue').default})
