import moment from "moment";
import type { BarData, PieData } from "~/components/Chart/configuration/options";

export const mapDataValue = (value: any, type: Superset.DataType) => {
  switch (type) {
    case Superset.DataType.Date:
      return moment.utc(value).format("YYYY-MM-DD");
    case Superset.DataType.Number:
      return parseFloat(value) || 0;
    case Superset.DataType.String:
      return String(value ?? "");
  }
};

export const supersetToBarData = (data: Superset.ChartData) => {
  const apexData: BarData = {
    series: [],
    xaxis: {
      categories: [],
    },
  };

  try {
    const dataResult = data.result[0];
    if (dataResult) {
      apexData.xaxis.tickAmount = Math.min(dataResult.data.length, 10);

      for (let c = 0; c < dataResult.colnames.length; c++) {
        const name = dataResult.colnames[c];

        if (c === 0) continue;

        apexData.series.push({ data: [], name });
      }

      for (let i = 0; i < dataResult.data.length; i++) {
        const datapoint = dataResult.data[i];

        for (let c = 0; c < dataResult.colnames.length; c++) {
          const name = dataResult.colnames[c];
          const type = dataResult.coltypes[c] as Superset.DataType;

          if (name in datapoint) {
            const value = structuredClone(datapoint)[name];

            if (c === 0) {
              apexData.xaxis.categories.push(mapDataValue(value, type) as string);
            } else if (type === Superset.DataType.Number || type === Superset.DataType.String) {
              const series = apexData.series.find((s) => s.name === name);

              if (series) series.data.push(value);
            }
          }
        }
      }
    }
  } catch (e) {
    console.log("apexData error:", e);
  }

  return apexData;
};

export const supersetToPieData = (data: Superset.ChartData) => {
  const apexData: PieData = {
    labels: [],
    series: [],
  };

  try {
    const dataResult = data.result[0];
    if (dataResult) {
      for (let i = 0; i < dataResult.data.length; i++) {
        const datapoint = dataResult.data[i];

        for (let c = 0; c < dataResult.colnames.length; c++) {
          const name = dataResult.colnames[c];
          const type = dataResult.coltypes[c] as Superset.DataType;

          if (name in datapoint) {
            const value = structuredClone(datapoint)[name];

            if (c === 0) {
              apexData.labels.push(mapDataValue(value, type) as string);
            } else if (type === Superset.DataType.Number) {
              apexData.series.push(value);
            }
          }
        }
      }
    }
  } catch (e) {
    console.log("apexData error:", e);
  }

  return apexData;
};

export const supersetToTableData = (data: Superset.ChartData): Superset.TableData => {
  const tableData: Superset.TableData = {
    headers: [],
    rows: [],
  };

  try {
    const dataResult = data.result[0];

    if (dataResult) {
      tableData.headers.push(...dataResult.colnames);
      tableData.rows = dataResult.data.map(() => []);

      dataResult.data.forEach((datapoint, d) => {
        dataResult.colnames.forEach((name, c) => {
          if (name in datapoint) {
            tableData.rows[d].push(datapoint[name]);
          }
        });
      });
    }
  } catch (e) {
    console.log("tabledata error:", e);
  }

  return tableData;
};

export const mapVizType = (type: string): Superset.ChartConfigType => {
  switch (type) {
    case "pie":
      return "pie";
    case "table":
      return "table";
    case "echarts_timeseries_bar":
      return "bar";
    case "echarts_area":
    case "echarts_timeseries_line":
      return "line";
    default:
      return "table";
  }
};

export const useSupersetData = (data: Superset.ChartData, type: string): Superset.ChartConfig => {
  const configType = mapVizType(type);
  switch (configType) {
    case "bar":
      return { type: configType, data: supersetToBarData(data) };
    case "line":
      return { type: configType, data: supersetToBarData(data) };
    case "pie":
      return { type: configType, data: supersetToPieData(data) };
    case "table":
      return { type: configType, data: supersetToTableData(data) };
  }
};

export namespace Superset {
  type User = {
    first_name: string;
    last_name: string;
    id?: number;
  };

  type Dashboard = {
    dashboard_title: string;
    id: number;
  };

  type Table = {
    default_endpoint: string;
    table_name: string;
  };

  type Tag = {
    id: number;
    name: string;
    type: number;
  };

  export type ChartResultItem = {
    cache_timeout: number;
    certification_details: string;
    certified_by: string;
    changed_by: User;
    changed_by_name: string;
    changed_on_delta_humanized: string;
    changed_on_dttm: string;
    changed_on_utc: string;
    created_by: User;
    created_by_name: string;
    created_on_delta_humanized: string;
    dashboards: Dashboard;
    datasource_id: number;
    datasource_name_text: string;
    datasource_type: string;
    datasource_url: string;
    description: string;
    description_markeddown: string;
    edit_url: string;
    form_data: string;
    id: number;
    is_managed_externally: boolean;
    last_saved_at: string;
    last_saved_by: User;
    owners: User;
    params: string;
    slice_name: string;
    slice_url: string;
    table: Table;
    tags: Tag;
    thumbnail_url: string;
    url: string;
    viz_type: string;
  };

  export type Charts = {
    count: number;
    description_columns: { [key: string]: string };
    ids: string[];
    label_columns: { [key: string]: string };
    list_columns: string[];
    list_title: string;
    order_columns: string[];
    result: ChartResultItem[];
  };

  type AnnotationData = {
    [key: string]: string; // Represents an object with an unknown number of string properties
  };

  export type DataResultItem = {
    annotation_data: AnnotationData[];
    applied_filters: any[]; // If the structure of filters is known, replace any with a more specific type
    cache_key: string;
    cache_timeout: number;
    cached_dttm: string;
    colnames: string[];
    coltypes: number[];
    data: any[];
    error: string;
    from_dttm: number;
    is_cached: boolean;
    query: string;
    rejected_filters: any[];
    rowcount: number;
    stacktrace: string;
    status: string;
    to_dttm: number;
  };

  export type ChartData = {
    result: DataResultItem[];
  };

  type WarmupResultItem = {
    chart_id: number;
    viz_error: string;
    viz_status: string;
  };

  export type ChartWarmup = {
    result: WarmupResultItem[];
  };

  export type Tokens = { access_token: string; refresh_token: string };

  export enum DataType {
    Number = 0,
    String = 1,
    Date = 2,
  }

  export type TableData = { headers: string[]; rows: (string | number)[][] };

  export interface ChartConfigBase {
    type: ChartConfigType;
  }

  export type ChartConfigType = ChartConfig["type"];

  export interface BarChartConfig extends ChartConfigBase {
    type: "bar";
    data: BarData;
  }

  export interface LineChartConfig extends ChartConfigBase {
    type: "line";
    data: BarData;
  }

  export interface PieChartConfig extends ChartConfigBase {
    type: "pie";
    data: PieData;
  }

  export interface TableChartConfig extends ChartConfigBase {
    type: "table";
    data: TableData;
  }

  export type ChartConfig = LineChartConfig | BarChartConfig | PieChartConfig | TableChartConfig;
}
